.skeleton-loader {
  width: 100%;
  height: 100%;
  background: #ccc;
  background-image: linear-gradient(
    90deg,
    #ccc 25%,
    #e0e0e0 50%,
    #ccc 75%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
